<template>
  <div>
    <div class="d-flex justify-content-between">
      <p class="fw-bolder fs-4">{{ question }}</p>
      <div class="d-flex">
        <vs-button
          class="text-dark shadow"
          color="#fff"
          type="button"
          @click="lookAnswer"
        >
          <span
            class="fw-bolder"
            :class="{
              'uil-minus': visibleAnswer,
              'uil-plus ': !visibleAnswer,
            }"
          ></span>
        </vs-button>
        <vs-button
          type="button"
          color="#fff"
          class="text-dark shadow"
          @click="handleEdit"
          v-if="isItForAdmin"
        >
          <span class="uil-pen"></span>
        </vs-button>
        <vs-button
          type="button"
          danger
          class="text-light shadow"
          @click="handleDelete"
          v-if="isItForAdmin"
        >
          <span class="uil-trash-alt"></span>
        </vs-button>
      </div>
    </div>
    <p v-if="visibleAnswer">{{ answer }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['answer', 'question', 'id', 'userRole'],
  data() {
    return {
      visibleAnswer: false,
    };
  },
  computed: {
    ...mapGetters({
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  methods: {
    lookAnswer() {
      this.visibleAnswer = !this.visibleAnswer;
    },
    handleEdit() {
      const faq = {
        _id: this.id,
        answer: this.answer,
        question: this.question,
      };
      this.$emit('editFaq', faq);
    },
    handleDelete() {
      this.$emit('deleteFaq', this.id);
    },
  },
};
</script>

<style></style>
