<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Manual payment general"
      ref="pageForm"
      :items="items"
    >
    </PageHeader>
    <add-faq
      ref="addFaq"
      :typeform="typeform"
      @closeModal="closeModal"
    ></add-faq>
    <div class="card">
      <div class="card-body">
        <div class="header text-center">
          <div class="row align-items-center justify-content-between">
            <div class="col-12">
              <vs-button
                type="button"
                class="ms-auto"
                @click="openModal"
                v-if="isItForAdmin"
                >{{ $t('faq.add') }}</vs-button
              >
            </div>
            <div class="col-12">
              <p class="fw-bolder text-muted">FAQs</p>
            </div>
          </div>
          <h3>{{ $t('faq.title') }}</h3>
          <p class="text-muted">{{ $t('faq.subtitle') }}</p>
          <div class="w-50 mx-auto my-4">
            <vs-input
              v-model="filter"
              type="search"
              placeholder="Buscar..."
              primary
              class="shadow-lg"
              @keyup="handleSearch"
            >
              <template #icon>
                <i class="uil-search"></i>
              </template>
            </vs-input>
          </div>
        </div>
        <div class="faqs-container my-5">
          <div v-if="faqs.length">
            <div v-for="(faq, index) in faqs" :key="index" class="w-75 mx-auto">
              <faq
                :answer="faq.answer"
                :question="faq.question"
                :id="faq._id"
                :userRole="userRole"
                @editFaq="editFaq"
                @deleteFaq="deleteFaq"
              ></faq>
            </div>
          </div>
          <div v-else>
            <p class="w-75 mx-auto fs-4 fw-bolder text-center">
              {{ $t('faq.noContent') }}
            </p>
          </div>
        </div>
        <div class="text-center text-muted">
          <p>
            {{ $t('faq.footer') }}
            <router-link to="/technical-support" class="fw-bolder text-primary">
              {{ $t('faq.techSupport') }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main';
import PageHeader from '../../../components/page-header-simple.vue';
import { breadCrumbData } from '../../../helpers/breadcrumbs/breadCrumbs';
import AddFaq from './addFaq.vue';
import Faq from './faq.vue';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';

export default {
  components: { Layout, PageHeader, AddFaq, Faq },
  data() {
    return {
      title: this.$t('faq.title'),
      items: breadCrumbData.userHelp,
      filter: '',
      typeform: {},
      faqs: [],
      userRole: '',
      userData: {},
    };
  },
  computed: {
    ...mapGetters({
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  methods: {
    async getFaqs() {
      const { data } = await this.$http.get('/faqs');

      if (!data.ok) {
        return (this.faqs = []);
      }
      this.faqs = data.faqs;
    },
    openModal() {
      this.typeform = {};
      this.$refs.addFaq.modal = true;
    },
    closeModal() {
      this.$refs.addFaq.modal = false;
      this.getFaqs();
    },
    editFaq(faq) {
      this.typeform = faq;
      this.$refs.addFaq.modal = true;
    },
    async handleSearch() {
      if (!this.faqs.length) return;

      const { data } = await this.$http.get('faqs/find', {
        params: {
          question: this.filter,
        },
      });

      if (!data.ok) return;

      this.faqs = data.faqs;
    },
    getUserRole() {
      this.userData = this.$store.getters['user/getUserData'];
      this.userRole = this.userData.role.name;
    },
    async deleteFaq(id) {
      const { isConfirmed } = await Swal.fire({
        title: '¿Estás seguro de eliminar este F.A.Q?',
        icon: 'question',
        showDenyButton: true,
      });

      if (!isConfirmed) return;

      const { data } = await this.$http.delete(`faqs/${id}`);
      if (!data.ok) {
        return Swal.fire('Error eliminando F.A.Q', '', 'info');
      }

      Swal.fire('F.A.Q elminado', '', 'success');

      return this.getFaqs();
    },
  },
  mounted() {
    this.getFaqs();
    this.getUserRole();
  },
};
</script>

<style scoped></style>
